import Vue from 'vue';

// this is v1 of abstracting identifying multiple services, if it gets to big we might want to do it by library maybe
export const userTrackingIdentify = (currentUser) => {
  if (!Vue.prototype.$isProd) return;
  try {
    const { id, email } = currentUser;
    Vue.prototype.$sentry.setUser({ id, email });
    window.mixpanel?.identify(id);
  } catch (e) {
    reportError(e);
  }
}

export const updateUserTrackingProfile = (currentUser) => {
  if (!Vue.prototype.$isProd) return;
  try {
    const { email, full_name } = currentUser;
    window.mixpanel?.people.set({ $email: email, $name: full_name, 'Company Name': currentUser._embedded?.default_employer?.company_name, 'Company Id': currentUser._embedded?.default_employer?.company_id });
  } catch (e) {
    reportError(e);
  }
};
export const updateCompanyTrackingGroup = (currentCompany) => {
  if (!Vue.prototype.$isProd) return;
  try {
    const { id, email, created_at, name } = currentCompany;
    window.mixpanel.set_group('company_id', id);
    window.mixpanel.get_group('company_id', id).set({
      $name: name,
      $email: email,
      stripe_customer: currentCompany._embedded.customer?.stripe_customer_id,
      stripe_subscription: currentCompany._embedded.customer?.stripe_subscription_id,
      iata_ids: currentCompany._embedded.iatas?.map(iata => iata.id),
      created_at,
    })
  } catch (e) {
    reportError(e);
  }
}

export const setTrackingEvent = (eventName, eventDataObject) => {
  if (!Vue.prototype.$isProd) return;
  try {
    window.mixpanel?.track(eventName, eventDataObject);
    window.Intercom('trackEvent', eventName);
  } catch (e) {
    reportError(e);
  }
};

export const setLogoutTrackingEvent = () => {
  if (!Vue.prototype.$isProd) return;
  try {
    window.mixpanel?.reset();
  } catch (e) {
    reportError(e);
  }
};

export const reportError = (error) => {
  if (!Vue.prototype.$isProd && !Vue.prototype.$isStaging) return;
  Vue.prototype.$sentry?.captureException(error);
};

export const reportIssue = (message) => {
  if (!Vue.prototype.$isProd && !Vue.prototype.$isStaging) return;
  Vue.prototype.$sentry?.captureMessage(message);
};
