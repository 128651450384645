<template>
  <span :class="['status-dot', type, `${size}-size`]" />
</template>

<script>
export default {
  name: 'StatusDotLabel',
  props: {
    type: {
      type: String,
    },
    size: {
      type: String,
      default: 'is-default',
      validator: (val) => ['is-small', 'is-medium', 'is-default'].includes(val),
    }
  },
};
</script>

<style lang="scss">
.status-dot {
  display: inline-block;
  background-color: $canceled;
  width: pxToRem(10);
  height: pxToRem(10);
  margin: 0 pxToRem(5);
  border-radius: 100%;
  &.is-default-size {
    width: pxToRem(10);
    height: pxToRem(10);
  }
  &.is-medium-size {
    width: pxToRem(8);
    height: pxToRem(8);
  }
  &.is-small-size {
    width: pxToRem(6);
    height: pxToRem(6);
  }
  &.is-default { background-color: $grey; }
  &.is-auto-matched { background-color: $auto; }
  &.is-duplicate { background-color: $duplicate; }
  &.is-matched { background-color: $auto; }
  &.is-owed { background-color: $owed; }
  &.is-past_due, &.is-danger { background-color: $past_due; }
  &.is-paid { background-color: $paid; }
  &.is-primary { background-color: $primary; }
  &.is-indigo { background-color: $indigo; }
  &.is-success, &.is-reconciled { background-color: $green; }
  &.is-trip-upcoming { background-color: $auto; }
  &.is-trip-current { background-color: $success; }
  &.is-trip-past { background-color: $canceled; }
  &.is-upcoming { background-color: $upcoming; }
  &.is-warning { background-color: $warning;}
}
</style>
