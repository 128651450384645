<template>
  <b-tag
    class="badge-tag"
    :class="{
      'badge-tag--reversed': isReversed,
      'badge-tag--wrap-text': allowWrap,
      'no-fill': !hasFill,
      'preserve-case': preserveCase,
    }"
    :type="`${type} is-light`"
    rounded
    :size="size"
    :ellipsis="hasEllipsis"
    :icon="icon"
    :icon-pack="$defaultIconPack"
    :icon-type="iconType || type"
  >
    <StatusDotLabel
      v-if="statusDot"
      class="ml-0"
      :size="dotSize"
      :type="dotType || type"
    />
    <slot />
  </b-tag>
</template>

<script>
  import StatusDotLabel from '@/components/payments/StatusDotLabel.vue';

  export default {
    name: 'BadgeTag',
    components: { StatusDotLabel },
    props: {
      allowWrap: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'is-default'
      },
      dotType: {
        type: String,
        default: ''
      },
      statusDot: {
        type: Boolean,
        default: false
      },
      dotSize: {
        type: String,
        default: 'is-medium',
      },
      icon: {
        type: String,
        default: ''
      },
      isReversed: {
        type: Boolean,
        default: false
      },
      hasEllipsis: {
        type: Boolean,
        default: false
      },
      hasFill: {
        type: Boolean,
        default: true
      },
      iconType: {
        type: String,
        default: ''
      },
      preserveCase: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        validator: (val) => ['is-small', 'is-medium', 'is-large', ''].includes(val),
      },
    }
  }
</script>

<style lang="scss">
// see assets/scss/modules/tags.scss
</style>
